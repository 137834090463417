import './RequestBox.css';
import resources from './Request.resources.json';
import { useNavigate } from 'react-router';

export const RequestBox = () => {

  const navigator = useNavigate();

  return (
    <div className="box">
      <div className="container">
        <p>{resources.requestBox.title}</p>
        <p className="description">{resources.requestBox.description}</p>
        <div className="helpButton">
          <button type="button" className="btn btn-secondary me-2" onClick={() => navigator("/")}>{resources.requestBox.button}</button>
        </div>
      </div>
    </div>
  );
}
