import axios from 'axios';
import config from '../../utils/config';
import { Request, Response } from './models/Models'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"

const endpoint = "/Requests";

const service = {

  createRequest: async (request: Request) => {
    const url = `${config.api_proxy_url}${endpoint}`;
    return axios.post<Response>(url, request)
      .then(response => {
        return response.data;
      })
      .catch((err: any) => {
        throw err;
      });
  },
};

export default service;
