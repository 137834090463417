import { SplitLayout } from "../../shared/SplitLayout";
import UseIsMobile from "../../../services/useIsMobile";
import { Stack } from '../../stack/Stack';
import { Orientation } from '../../stack/models/Orientation';
import { RequestForm } from "../../request/RequestForm";
import { SupportBox } from "../../support/SupportBox";
import { Background } from "../../stack/models/Background";

export const RequestFormPage = () => {

  return (
    <>
      {UseIsMobile() &&
        <div className="container">
          <RequestForm />
        </div>
      }
      {!UseIsMobile() &&
        <SplitLayout
          left={
            <RequestForm />
          }
          right={
            <Stack orientation={Orientation.VERTICAL} background={Background.GREY}>
              <SupportBox />
            </Stack>
          }
        ></SplitLayout>
      }
    </>
  );
}
