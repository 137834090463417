import UseIsMobile from "../../services/useIsMobile";

export const NavIcon = ({ type }: { type: string | undefined }) => {
  return (
    <>
      {UseIsMobile() &&
        <span className="pe-2">
          {type === "TERMSOFUSE" &&
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.99984 0.166656C4.39984 0.166656 0.666504 3.89999 0.666504 8.49999C0.666504 13.1 4.39984 16.8333 8.99984 16.8333C13.5998 16.8333 17.3332 13.1 17.3332 8.49999C17.3332 3.89999 13.5998 0.166656 8.99984 0.166656ZM8.99984 9.33332C8.5415 9.33332 8.1665 8.95832 8.1665 8.49999V5.16666C8.1665 4.70832 8.5415 4.33332 8.99984 4.33332C9.45817 4.33332 9.83317 4.70832 9.83317 5.16666V8.49999C9.83317 8.95832 9.45817 9.33332 8.99984 9.33332ZM9.83317 12.6667H8.1665V11H9.83317V12.6667Z" fill="#696969" />
            </svg>
          }
          {type === "FAQ" &&
            <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.58333 0.666656C3.675 0.666656 0.5 3.84166 0.5 7.74999C0.5 11.6583 3.675 14.8333 7.58333 14.8333H8V17.3333C12.05 15.3833 14.6667 11.5 14.6667 7.74999C14.6667 3.84166 11.4917 0.666656 7.58333 0.666656ZM8.41667 12.75H6.75V11.0833H8.41667V12.75ZM8.75 8.76666C8.74167 8.77499 8.73333 8.79166 8.725 8.80832C8.68333 8.87499 8.64167 8.94166 8.60833 9.00832C8.59167 9.03332 8.58333 9.06666 8.575 9.09999C8.55 9.15832 8.525 9.21666 8.50833 9.27499C8.45 9.44999 8.425 9.63332 8.425 9.84166H6.75C6.75 9.41666 6.81667 9.05832 6.91667 8.75832C6.91667 8.74999 6.91667 8.74166 6.925 8.73332C6.93333 8.69999 6.95833 8.68332 6.96667 8.64999C7.01667 8.51666 7.075 8.39999 7.15 8.28332C7.175 8.24166 7.20833 8.19999 7.23333 8.15832C7.25833 8.12499 7.275 8.08332 7.3 8.05832L7.30833 8.06666C8.00833 7.14999 9.15 6.86666 9.24167 5.83332C9.31667 5.01666 8.73333 4.22499 7.93333 4.05832C7.06667 3.87499 6.28333 4.38332 6.01667 5.12499C5.9 5.42499 5.625 5.66666 5.28333 5.66666H5.11667C4.61667 5.66666 4.25 5.17499 4.39167 4.69166C4.85 3.17499 6.36667 2.11666 8.08333 2.36666C9.49167 2.57499 10.6167 3.73332 10.8583 5.14166C11.225 7.17499 9.5 7.66666 8.75 8.76666Z" fill="#696969" />
            </svg>
          }
          {type === "PRIVACY" &&
            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.325 1.13334L1.49167 3.72501C0.891667 3.99168 0.5 4.59168 0.5 5.25001V9.16668C0.5 13.7917 3.7 18.1167 8 19.1667C12.3 18.1167 15.5 13.7917 15.5 9.16668V5.25001C15.5 4.59168 15.1083 3.99168 14.5083 3.72501L8.675 1.13334C8.25 0.941677 7.75 0.941677 7.325 1.13334ZM5.74167 13.575L3.58333 11.4167C3.25833 11.0917 3.25833 10.5667 3.58333 10.2417C3.90833 9.91668 4.43333 9.91668 4.75833 10.2417L6.33333 11.8083L11.2333 6.90834C11.5583 6.58334 12.0833 6.58334 12.4083 6.90834C12.7333 7.23334 12.7333 7.75834 12.4083 8.08334L6.91667 13.575C6.6 13.9 6.06667 13.9 5.74167 13.575Z" fill="#696969" />
            </svg>
          }
          {type === "HELP" &&
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5ZM8.00833 13C7.425 13 6.95833 12.5333 6.95833 11.95C6.95833 11.3583 7.425 10.9083 8.00833 10.9083C8.6 10.9083 9.05 11.3583 9.05 11.95C9.04167 12.525 8.6 13 8.00833 13ZM10.5167 6.83333C9.88333 7.75833 9.28333 8.05 8.95833 8.64167C8.875 8.79167 8.825 8.90833 8.8 9.16667C8.75833 9.54167 8.425 9.81667 8.05 9.81667H8C7.56667 9.81667 7.225 9.45 7.26667 9.01667C7.29167 8.73333 7.35833 8.44167 7.51667 8.15833C7.925 7.43333 8.7 7 9.15 6.35833C9.625 5.68333 9.35833 4.41667 8.00833 4.41667C7.41667 4.41667 7.025 4.71667 6.78333 5.075C6.575 5.375 6.20833 5.51667 5.86667 5.375C5.425 5.2 5.26667 4.66667 5.53333 4.28333C6.04167 3.54167 6.89167 3 7.99167 3C9.225 3 10.0667 3.55833 10.5 4.26667C10.8667 4.86667 11.0833 5.99167 10.5167 6.83333Z" fill="#696969" />
            </svg>
          }
        </span>
      }
    </>
  );
};

