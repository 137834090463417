import './SplitLayout.css';
import config from "../../utils/config";

export function SplitLayout(props: any) {
    return <div className="main-panel container">
        <div className="leftPanel container">
            {props.left}
        </div>
        <div className="rightPanel sticky-top">
            {props.right}
        </div>
    </div>;
}