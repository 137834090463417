import { useEffect, useState } from 'react';
import resources from './Feedback.resources.json';
import './FeedbackForm.css';
import { Feedback } from './Models/Feedback';
import { Modal } from 'react-bootstrap';
import { service } from './Service';

export const FeedbackForm = ({ Section, RequestID }: { Section: string, RequestID?: string }) => {

  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [hover, setHover] = useState(0);
  const [isDisabled, setIsDisabled] = useState(true);

  const [countdown, setCountdown] = useState(5);

  const sendFeedback = async () => {
    const feedback: Feedback = {
      RequestID: RequestID === '' ? null : RequestID,
      Section: Section,
      Stars: rating,
      Comment: comment === '' ? 'No Comments' : comment,
    }

    await service.sendFeedback(feedback).then((result) => {
      setDone(true);
      setShowPopUp(false);
    }).catch(() => {
    });
  };

  const onClose = () => {
    setShowPopUp(false);
    setCountdown(0);
    setComment('');
    setRating(0);
  }

  const [done, setDone] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!done)
        setShowPopUp(true);

      setCountdown(countdown - 1);
      if (comment === '' && rating === 0) {
        if (countdown === 0) {
          setDone(true);
          setShowPopUp(false);
        }

      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [comment, countdown, done, rating, showPopUp]);

  return (
    <>
      {showPopUp &&
        <Modal show={showPopUp}>
          <Modal.Header><h4>{resources.feedback.title}</h4><button type="button" className="close" onClick={() => onClose()} data-dismiss="modal">X</button></Modal.Header>
          <Modal.Body>
            <div className="mb-8">
              <div className="d-flex flex-column justify-content-center">
                <label className="form-label">{resources.feedback.rating}</label>
                <div className="star-rating">
                  {[...Array(5)].map((star, index) => {
                    index += 1;
                    let key = index;
                    return (
                      <button type="button" key={key} className={index <= (hover || rating) ? "on" : "off"} onClick={() => { setRating(index); setIsDisabled(false); }} onMouseEnter={() => setHover(index)} onMouseLeave={() => setHover(rating)}>
                        <span className="star">&#9733;</span>
                      </button>
                    );
                  })}
                </div>            </div>
              <div className="d-flex flex-column justify-content-center">
                <label className="form-label">{resources.feedback.comment}</label>
                <input className='form-control' onChange={e => setComment(e.target.value)} placeholder={resources.feedback.comment} id="FeedbackComment" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-text me-1" onClick={() => onClose()}>{resources.feedback.close}</button>
            <button type="button" disabled={isDisabled} onClick={sendFeedback} className="btn btn-sm btn-primary">{resources.feedback.submit}</button>
          </Modal.Footer>
        </Modal>
      }
    </>
  );
};
