import './SupportBox.css';
import resources from './Support.resources.json';
import { useNavigate } from 'react-router';

export const SupportBox = () => {
  const navigator = useNavigate();

  return (
    <div className="box">
      <div className="container">
        <p>{resources.supportBox.title}</p>
        <p className="description">{resources.supportBox.description}
        </p>
        <div className="helpButton">
          <button type="button" className="btn btn-secondary me-2" onClick={() => navigator("/support")}>{resources.supportBox.button}</button>
        </div>
      </div>
    </div>
  );
}
