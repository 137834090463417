import React from 'react';
import ScrollToTop from '../routeHandler/ScrollToTop';
import {Outlet} from "react-router";
import { Bar } from '../navigation/Bar';

const Layout = () => {
    return (
        <>
            <ScrollToTop/>
            <Bar/>
            <main><Outlet/></main>
        </>
    );
};
export default Layout;
