import './AccordionViewer.css'
import parser from 'html-react-parser';
import { Accordion } from '@bcg-web/bcg-react-bootstrap';
import { Document } from './models/Document';

export const AccordionViewer = ({ data }: { data: Document | undefined }) => {

    return (
        <>
            {data &&
                <>
                    <h3 className="pageHeading">{data?.Title}</h3>
                    {data?.SubTitle && <small className="text-sm">{data?.SubTitle}</small>}
                    {data?.Description && <h4 className="pb-2 pt-4">{data?.Description}</h4>}
                    {data?.Alternate && <small className="text-sm">{parser(`${data?.Alternate}`)}</small>}
                    {data?.Disclaimer && <p className="pb-2">{data?.Disclaimer}</p>}

                    <Accordion defaultActiveKey="0">
                        {data?.Sections.map(section =>
                            <Accordion.Item eventKey={section.Key} key={section.Key}>
                                <Accordion.Header>{section.Title}</Accordion.Header>
                                <Accordion.Body>
                                    {
                                        section.Paragraphs.map(paragraph =>
                                            <p key={paragraph.Key}>{parser(`${paragraph.Content}`)}</p>
                                        )
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        )}
                    </Accordion>
                </>
            }
        </>
    )
}
