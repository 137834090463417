import { AccordionViewer } from "../../documentViewer/AccordionViewer";
import { SplitLayout } from "../../shared/SplitLayout";
import UseIsMobile from "../../../services/useIsMobile";
import { Stack } from '../../stack/Stack';
import { Orientation } from '../../stack/models/Orientation';
import { useEffect, useState } from "react";
import service from "../../frequentlyAskedQuestions/Service";
import { Document } from '../../documentViewer/models/Document';
import { RequestBox } from "../../request/RequestBox";
import { Background } from "../../stack/models/Background";
import resources from '../../frequentlyAskedQuestions/FrequentlyAskedQuestion.resources.json';
import { State } from "../../shared/models/State";

export const FrequentlyAskedQuestionsPage = () => {

  const [document, setDocument] = useState<Document>();
  const [state, setState] = useState<State>(State.LOADING);

  useEffect(() => {
    (async () => {
      await service.getFrequentlyAskedQuestions()
        .then(response => {
          if (response) {
            response.Title = resources.frequentlyAskedQuestion.title;
            response.Disclaimer = resources.frequentlyAskedQuestion.subTitle;
          }
          setDocument(response);
          setState(State.OK);
        }).catch(() => {
          setState(State.FAILED);
        });

    })();
  }, []);

  return (
    <>
      {UseIsMobile() &&
        <div className="container">
          {state === State.OK && <AccordionViewer data={document as Document} />}
          {state === State.FAILED && <div>Can't' show this right now</div>}
        </div>
      }
      {!UseIsMobile() &&
        <SplitLayout
          left={
            <>
              {state === State.OK && <AccordionViewer data={document as Document} />}
              {state === State.FAILED && <div>Can't' show this right now</div>}
            </>
          }
          right={
            <Stack orientation={Orientation.VERTICAL} background={Background.WHITE}>
              <RequestBox />
            </Stack>
          }
        ></SplitLayout>
      }
    </>
  );
}