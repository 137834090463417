import { SplitLayout } from "../../shared/SplitLayout";
import UseIsMobile from "../../../services/useIsMobile";
import { Stack } from '../../stack/Stack';
import { Orientation } from '../../stack/models/Orientation';
import { SupportBox } from "../../support/SupportBox";
import { Background } from "../../stack/models/Background";
import ImageViewer from "../../contractViewer/ImageViewer";
import { AcknowledgmentForm } from "../../acknowledgment/AcknowledgmentForm";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import service from "../../acknowledgment/Service";
import { Project } from "../../acknowledgment/models/Models";
import resources from '../../acknowledgment/Acknowledgment.resources.json';
import { State } from "../../shared/models/State";

export const AcknowledgmentFormPage = () => {

  const params = useParams();
  const navigator = useNavigate();

  const [project, setProject] = useState<Project>();
  const [state, setState] = useState<State>(State.LOADING);

  useEffect(() => {
    (async () => {
      await service.getProject(params.token)
        .then(response => {
          setProject(response);
          setState(State.OK);
        }).catch(() => {
          navigator("/notLoaded", { state: params.token });
        });
    })();
  }, [params.token]);

  return (
    <>
      {UseIsMobile() &&
        <>
          <div className="container">
            {(state === State.OK || state === State.LOADING) &&
              <>
                <h4>{resources.acknowledgement.project} {project?.Transaction}</h4>
                <ImageViewer token={params.token} />
                <AcknowledgmentForm token={params.token} />
              </>}
          </div>
        </>
      }
      {!UseIsMobile() &&
        <SplitLayout
          left={
            <SplitLayout
              left={
                <>
                  {(state === State.OK || state === State.LOADING) &&
                    <div>
                      <h4>{resources.acknowledgement.project} {project?.Transaction}</h4>
                      <ImageViewer token={params.token} />
                    </div>
                  }
                </>
              }
              right={
                <div className="pe-4" style={{ "borderLeft": "1px solid lightgrey" }}>
                  <AcknowledgmentForm token={params.token} />
                </div>
              }
            ></SplitLayout>
          }
          right={
            <Stack orientation={Orientation.VERTICAL} background={Background.GREY}>
              <SupportBox />
            </Stack>
          }
        ></SplitLayout >
      }
    </>
  );
}
