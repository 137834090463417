import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Request } from "./models/Models"
import { State } from "../shared/models/State"
import resources from './RequestForm.resources.json';
import './RequestForm.css'
import service from "./Service";
import ReCAPTCHA from "react-google-recaptcha";
import parser from 'html-react-parser';
import { ErrorPopUp } from "../errors/ErrorPopUp";
import { SpinnerButton } from "../spinnerButton/SpinnerButton";
import config from '../../utils/config';
import { ToolTipIcon } from "../shared/ToolTipIcon";
import UseIsMobile from "../../services/useIsMobile";

export const RequestForm = ({ testState, testRequestID }: { testState?: State, testRequestID?: string }) => {
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const navigator = useNavigate();

  const [state, setState] = useState<State>(testState ?? State.OK);
  const [requestID, setRequestID] = useState<string>(testRequestID ?? "");

  const { register, handleSubmit, formState: { errors } } = useForm<Request>({
    shouldUseNativeValidation: true,
    shouldFocusError: false
  });
  const isFormInvalid = Object.keys(errors).length > 0;

  const onSubmit = async (data: Request) => {
    setState(State.SUBMITTING);

    if (recaptchaRef?.current != null) {
      const token = await recaptchaRef.current.executeAsync();
      const newRequest: Request = {
        Captcha: token ?? "",
        Project: { Transaction: data.Project.Transaction },
        Partner: { Role: "PARTNER", EMail: data.Partner.EMail, Name: data.Partner.Name },
        Coordinator: {
          FirstNames: data.Coordinator.FirstNames,
          LastNames: data.Coordinator.LastNames,
          EMail: data.Coordinator.EMail,
          Company: data.Coordinator.Company
        },
      }

      await service.createRequest(newRequest).then((result) => {
        setRequestID(result.Content);
        setState(State.SUBMITTED);
        navigator("/requestConfirmation", { state: result.Content ?? requestID });
      }).catch(() => {
        setState(State.FAILED);
      });
    };
  }

  const isNotEmpty = (value: any) => {
    return value.trim() !== '';
  };

  const clearState = () => {
    setState(State.OK);
    recaptchaRef.current?.reset();
  };

  const isAccepted = (value: any) => {
    return value === true;
  };

  return (
    <>
      <div className="whiteback requestPage">
        {(state === State.OK || state === State.SUBMITTING || state === State.FAILED) &&
          <>
            <form onSubmit={handleSubmit(onSubmit)} className={isFormInvalid ? 'was-validated' : ''}>
              <fieldset disabled={state === State.SUBMITTING}>
                <div className="headerSection">
                  <p className="mt-4">{resources.request.title}<ToolTipIcon toolTip={resources.request.tooltip} /></p>
                </div>
                <div className="pageInfo">
                  <p>{resources.request.description}</p>
                </div>
                <div className="request">
                  <div className="form-group">
                    <div className="row">
                      <p>{resources.request.details.title}</p>
                      <div className="col-md-3">
                        <label className="form-label">{resources.request.details.firstName.label}</label>
                        <input
                          className={`form-control ${errors['Coordinator']?.FirstNames ? 'is-invalid' : ''}`}
                          type="text" placeholder={resources.request.details.firstName.placeHolder}
                          id="Coordinator.FirstNames"
                          {...register('Coordinator.FirstNames', {
                            required: resources.request.details.firstName.message,
                            validate: {
                              isEmpty: e => isNotEmpty(e) || resources.request.details.firstName.validation
                            }
                          })}
                        />
                        {errors['Coordinator']?.FirstNames && <div className="invalid-feedback">
                          <span>{resources.request.details.firstName.required}</span></div>}
                      </div>
                      <div className="col-md-3">
                        <label className="form-label">{resources.request.details.lastName.label}</label>
                        <div className="form-group mb-3">
                          <input
                            className={`form-control ${errors['Coordinator']?.LastNames ? 'is-invalid' : ''}`}
                            type="text" placeholder={resources.request.details.lastName.placeHolder}
                            id="Coordinator.LastNames"
                            {...register('Coordinator.LastNames', {
                              required: resources.request.details.lastName.required,
                              validate: { isEmpty: e => isNotEmpty(e) || resources.request.details.lastName.validation }
                            })}
                          />
                          {errors['Coordinator']?.LastNames && <div className="invalid-feedback">
                            <span>{resources.request.details.lastName.required}</span></div>}
                        </div>
                      </div>
                      <div className="col-md">
                        <label className="form-label">{resources.request.details.legalEntity.label}</label>
                        <input
                          className={`form-control ${errors['Coordinator']?.Company ? 'is-invalid' : ''}`}
                          type="text" placeholder={resources.request.details.legalEntity.placeHolder}
                          id="Coordinator.Company"
                          {...register('Coordinator.Company', {
                            required: resources.request.details.legalEntity.required,
                            validate: { isEmpty: e => isNotEmpty(e) || resources.request.details.legalEntity.validation }
                          })} />
                        {errors['Coordinator']?.Company && <div className="invalid-feedback">
                          <span>{resources.request.details.legalEntity.required}</span></div>}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md">
                      <label className="form-label">{resources.request.details.eMail.label}</label>
                      <input
                        className={`form-control ${errors['Coordinator']?.EMail ? 'is-invalid' : ''}`}
                        type="text" placeholder={resources.request.details.eMail.placeHolder}
                        id="Coordinator.Email"
                        {...register('Coordinator.EMail', {
                          required: resources.request.details.eMail.required,
                          pattern: {
                            value: /^\S+@\S+\.\S+$/,
                            message: resources.request.details.eMail.validation,
                          }
                        })} />
                      {errors['Coordinator']?.EMail && <div className="invalid-feedback">
                        <span>{resources.request.details.eMail.required}</span></div>}
                    </div>
                    <div className="col-md">
                      <label className="form-label">{resources.request.details.project.label}</label>
                      <input
                        className={`form-control ${errors['Project']?.Transaction ? 'is-invalid' : ''}`}
                        type="text" placeholder={resources.request.details.project.placeHolder}
                        id="Project.Transaction"
                        {...register('Project.Transaction', {
                          required: resources.request.details.project.required,
                          validate: { isEmpty: e => isNotEmpty(e) || resources.request.details.project.validation }
                        })} />
                      {errors['Project']?.Transaction && <div className="invalid-feedback">
                        <span>{resources.request.details.project.required}</span></div>}
                    </div>
                  </div>
                  <div className="row">
                    <p className="mt-4">{resources.request.partner.title}<ToolTipIcon toolTip={resources.request.partner.tooltip} /></p>
                    <div className="col-md">
                      <label className="form-label">{resources.request.partner.name.label}</label>
                      <input className={`form-control ${errors['Partner']?.Name ? 'is-invalid' : ''}`}
                        type="text" placeholder={resources.request.partner.name.placeHolder}
                        id="Partner.Name"
                        {...register('Partner.Name', {
                          required: resources.request.partner.name.required,
                          validate: { isEmpty: e => isNotEmpty(e) || resources.request.partner.name.required }
                        })} />
                      {errors['Partner']?.Name && <div className="invalid-feedback">
                        <span>{resources.request.partner.name.required}</span></div>}
                    </div>
                    <div className="col-md">
                      <label className="form-label">{resources.request.partner.eMail.label}</label>
                      <input className={`form-control ${errors['Partner']?.EMail ? 'is-invalid' : ''}`}
                        type="text" placeholder={resources.request.partner.eMail.placeHolder}
                        id="Partner.EMail"
                        {...register('Partner.EMail', {
                          required: resources.request.partner.eMail.required,
                          pattern: {
                            value: /^\S+@\S+\.\S+$/,
                            message: resources.request.partner.eMail.validation,
                          }
                        })} />
                      {errors['Partner']?.EMail && <div className="invalid-feedback">
                        <span>{resources.request.partner.eMail.required}</span></div>}
                    </div>
                  </div>
                  <div className="form-check form-group mt-4">
                    <input className={`form-check-input ${errors['IsAccepted'] ? 'is-invalid' : ''}`}
                      defaultChecked={false} type="checkbox" id="IsAccepted"
                      {...register('IsAccepted', {
                        validate: { isAccepted: e => (isAccepted(e)) || resources.consentcheckbox.required },
                      })}
                    />
                    <label className="form-check-label">
                      {parser(`${resources.consentcheckbox.description}`)}
                    </label>
                    {errors['IsAccepted'] && <div className="invalid-feedback">
                      <span>{resources.consentcheckbox.required}</span></div>}
                  </div>
                  <div className="row button-row">
                    {UseIsMobile() && <SpinnerButton type="submit" state={state}>{resources.request.submit}</SpinnerButton>}
                    <div className="col-md d-flex flex-row-reverse">
                      {!UseIsMobile() && <SpinnerButton type="submit" state={state}>{resources.request.submit}</SpinnerButton>}
                      <ReCAPTCHA
                        badge="bottomleft"
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={config.captchaKey}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
            {(state === State.FAILED) &&
              <ErrorPopUp code={500} onClosed={clearState} />}
          </>
        }
      </div >
    </>
  );
}
