import React, { useState } from "react";
import '../../index.css'
import './AcknowledgmentForm.css'
import "@bcg-web/bcg-react-bootstrap";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '@bcg-web/bcg-bootstrap/dist/bcg-bootstrap.min.css';
import "@bcg-web/bcg-react-datepicker/dist/bcg-react-datepicker.css";
import BCGDatePicker from "@bcg-web/bcg-react-datepicker";
import { Acknowledgment } from "./models/Models"
import { State } from "../shared/models/State";
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import service from "./Service";
import resources from '../../resources.json';
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment-timezone";
import parser from 'html-react-parser';
import { ErrorPopUp } from "../errors/ErrorPopUp";
import { SpinnerButton } from "../spinnerButton/SpinnerButton";
import config from '../../utils/config';
import UseIsMobile from "../../services/useIsMobile";


export const AcknowledgmentForm = ({ testState, token }: { testState?: State, token: string | undefined }) => {

  const recaptchaRef = React.createRef<ReCAPTCHA>();

  const [state, setState] = useState<State>(testState ?? State.OK);

  const navigator = useNavigate();
  const { control, register, handleSubmit, formState: { errors } } = useForm<Acknowledgment>({ shouldUseNativeValidation: true, shouldFocusError: false });
  const isFormInvalid = Object.keys(errors).length > 0;
  const [startDate, setStartDate] = useState<Date | undefined>(new Date());
  const [isChecked, setIsChecked] = useState(false);

  const onSubmit = async (data: Acknowledgment) => {

    if (recaptchaRef?.current != null) {
      const captchaToken = await recaptchaRef.current.executeAsync();

      if (startDate) {
        let dateTime = new Date((new Date().setHours(0, 0, 0, 0)));
        const acknowledgment: Acknowledgment = {
          Token: token,
          Captcha: captchaToken ?? "",
          FirstNames: data.FirstNames,
          LastNames: data.LastNames,
          EMail: data.EMail,
          Company: data.Company,
          HasConfirmed: isChecked,
          SignedOn: moment.utc(dateTime.toString()).toDate(),
          TimeZone: moment(dateTime.toISOString()).format()
        }

        setState(State.SUBMITTING);

        await service.acknowledge(acknowledgment)
          .then(() => {
            setState(State.SUBMITTED);
            navigator("/acknowledgmentConfirmation", { state: State.SUBMITTED });
          }).catch(() => {
            setState(State.FAILED);
          });
      }
    };
  };

  const isNotEmpty = (value: any) => {
    return value.trim() !== '';
  };

  const handleChange = () => {

    setIsChecked(!isChecked);
  };

  const clearState = () => {
    setState(State.OK);
    recaptchaRef.current?.reset();
  };

  return (
    <>
      <div className="whiteback requestPage">
        {(state === State.OK || state === State.SUBMITTING || state === State.FAILED) &&
          <>
            <form onSubmit={handleSubmit(onSubmit)} className={isFormInvalid ? 'was-validated' : ''}>
              <fieldset disabled={state === State.SUBMITTING}>

                <div className="container content mainPanel">
                  <div>
                    <div>
                      <p style={{ fontSize: 16 }}>{resources.acknowledgement.title}</p>
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label">{resources.acknowledgement.firstName.label}</label>
                      <input className="form-control" type="text" placeholder={resources.acknowledgement.firstName.placeHolder} id="FirstNames"
                        {...register('FirstNames', {
                          required: resources.acknowledgement.firstName.required,
                          validate: { isEmpty: e => isNotEmpty(e) || resources.acknowledgement.firstName.validation }
                        })}
                      />
                      <div className="invalid-feedback"><span>{resources.acknowledgement.firstName.required}</span></div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label">{resources.acknowledgement.lastName.label}</label>
                      <input className="form-control" type="text" placeholder={resources.acknowledgement.lastName.placeHolder} id="LastNames"
                        {...register('LastNames', {
                          required: resources.acknowledgement.lastName.required,
                          validate: { isEmpty: e => isNotEmpty(e) || resources.acknowledgement.lastName.validation }
                        })}
                      />
                      <div className="invalid-feedback"><span>{resources.acknowledgement.lastName.required}</span></div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label">{resources.acknowledgement.eMail.label}</label>
                      <input className="form-control" type="text" placeholder={resources.acknowledgement.eMail.placeHolder} id="EMail"
                        {...register('EMail', {
                          required: resources.acknowledgement.eMail.required,
                          pattern: {
                            value: /^\S+@\S+\.\S+$/,
                            message: resources.acknowledgement.eMail.validation,
                          }
                        })} />
                      {<div className="invalid-feedback"><span>{resources.acknowledgement.eMail.required}</span></div>}
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label">{resources.acknowledgement.legalEntity.label}</label>
                      <input className="form-control" type="text" placeholder={resources.acknowledgement.legalEntity.placeHolder} id="Company"
                        {...register('Company', {
                          required: resources.acknowledgement.legalEntity.required,
                          validate: { isEmpty: e => isNotEmpty(e) || resources.acknowledgement.legalEntity.validation }
                        })} />
                      {<div className="invalid-feedback"><span>{resources.acknowledgement.legalEntity.required}</span></div>}
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label">{resources.acknowledgement.requestDate.label}</label>
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <BCGDatePicker id="RequestDate" disabled="true" selected={startDate} onChange={(date: React.SetStateAction<Date | undefined>) => { if (date) { onChange(date); setStartDate(date) } }}
                            maxDate={new Date()}
                            useWeekdaysShort={true}
                            placeholderText={resources.acknowledgement.requestDate.placeHolder} />
                        )}
                        name="SignedOn"
                      />

                    </div>
                    <div className="form-group mb-3">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" id="HasConfirmed" checked={isChecked}
                          {...register('HasConfirmed', {
                            required: resources.consentcheckbox.required,
                            onChange: handleChange
                          })} />
                        <label className="form-check-label" htmlFor="HasConfirmed">
                          {parser(`${resources.consentcheckbox.description}`)}
                        </label>
                        {<div className="invalid-feedback"><span>{resources.consentcheckbox.required}</span></div>}
                      </div>
                    </div>
                    <div className="row">
                      {UseIsMobile() && <SpinnerButton type="submit" state={state}>{resources.acknowledgement.submit}</SpinnerButton>}
                      <div className="col-md text-center">
                        {!UseIsMobile() && <SpinnerButton type="submit" state={state}>{resources.acknowledgement.submit}</SpinnerButton>}
                        <ReCAPTCHA
                          badge="bottomleft"
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey={config.captchaKey}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
            {(state === State.FAILED) &&
              <ErrorPopUp code={500} onClosed={clearState} />}
          </>
        }
      </div>
    </>
  );
}
