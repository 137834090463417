import axios from 'axios';
import config from '../utils/config';
import { Request, Acknowledgement, Response, Template, SupportRequest } from '../Models/Model';
import { Image } from '../Models/Image';
import axiosRetry from 'axios-retry';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"

axiosRetry(axios);

const endpoints = {
    CREATE_REQUEST: "/Requests",
    SUPPORT: "/Support"
};

const httpService = {

    sendFeedback: async (section: string, stars: number, comment?: string | null, requestID?: string | null, ipAddress?: string | null) => {
        const url = `${config.api_proxy_url}${endpoints.SUPPORT}/feedback`;

        const feedback = {
            RequestID: requestID === '' ? null : requestID,
            Section: section,
            Stars: stars,
            Comment: comment === '' ? null : comment,
        }

        return axios.post(url, feedback)
            .then(response => {
                return response.data;
            })
            .catch((err: any) => {
                throw err;
            });
    },

    createRequest: async (request: Request) => {
        const url = `${config.api_proxy_url}${endpoints.CREATE_REQUEST}`;
        return axios.post<Response>(url, request)
            .then(response => {
                return response.data;
            })
            .catch((err: any) => {
                throw err;
            });
    },
    acknowledge: async (token: string | undefined, acknowledgement: Acknowledgement) => {
        if (token === undefined) {
            //error
        }
        const url = `${config.api_proxy_url}/requests/acknowledge/${token}`;
        return axios.post(url, acknowledgement)
            .catch((err: any) => {
                throw err;
            });
    },
    sendSupportEmail: async (supportRequest: SupportRequest) => {
        const url = `${config.api_proxy_url}/requests/sendsupportrequest`;
        return axios.post<any>(url, supportRequest)
            .catch((err: any) => {
                throw err;
            });
    },

    approve: async (token: string | null | undefined) => {
        if (token == null || token === undefined) {
            return false;
        }
        const url = `${config.api_proxy_url}/requests/approve/${token}`;
        return axios.get<any>(url)
            .catch((error: any) => {
                throw error;
            });
    },

    getFrequentlyAskedQuestions: async () => {
        const url = `${config.api_proxy_url}/Support/frequentlyAskedQuestions`;
        return axios.get<any>(url).then(response => {console.log(response); return response;})
            .catch((error: any) => {
                throw error;
            });
    },

    decline: async (token: string | null | undefined) => {
        if (token == null || token === undefined) {
            return false;
        }
        const url = `${config.api_proxy_url}/requests/decline/${token}`;
        return axios.get(url)
            .catch((error: any) => {
                throw error;
            });
    },

    template: async (token: string | undefined) => {
        const url = `${config.api_proxy_url}/templates/${token}`;
        return axios.get<Template>(url)
            .then(response => {
                return response.data.Content;
            })
            .catch((err: any) => {
                throw err;
            });
    },

    templateImages: async (token: string | undefined) => {
        const url = `${config.api_proxy_url}/templates/${token}/images`;
        return axios.get<Image[]>(url)
            .then(response => {
                return response.data;
            })
            .catch((err: any) => {
                throw err;
            });
    },

    project: async (token: string | undefined) => {
        const url = `${config.api_proxy_url}/templates/${token}`;
        return axios.get<Template>(url)
            .then(response => {
                return response.data.ProjectTransaction;
            })
            .catch((err: any) => {
                throw err;
            });
    },

    templateID: async (token: string | undefined) => {
        const url = `${config.api_proxy_url}/templates/${token}/id`;
        return axios.get<Template>(url)
            .then(response => {
                const id = response.data.TemplateID;
                return id;
            })
            .catch((err: any) => {
                throw err;
            });
    },
};
export default httpService;
