import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import './../resolveRequest/RequestDecline.css';

import httpService from "../../services/httpService";
import Loading from '../routeHandler/Loading';

const RequestDecline = () => {
    const params = useParams();

    const [state, setState] = useState(0);

    useEffect(() => {
        (async () => {
            if (params.token != null) {
                await httpService.decline(params.token)
                    .then(() => {
                        setState(200);
                    })
                    .catch(error => {
                        setState(error.response.status);
                    });
            }
        })();
    }, [params.token]);

    return (
        <div>
            {
                state === 0 &&
                <Loading></Loading>
            }
            {
                (state === 200 || state === 204) &&
                <div className="container">
                    <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                        <div className="alert alert-success d-flex align-items-center" role="alert">
                            <svg width="25" height="25" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM5 10.1845L6.42222 8.70723L8.68889 11.1078L13.5778 5.93735L15 7.41462L8.68889 14.0623L5 10.1845Z" fill="green" /></svg>
                            <div className='messageDetails'>The request has been approved, you may close this window.</div>
                        </div>
                    </div>
                </div>
            }
            {
                (state === 401 || state === 403) &&
                <div className="container">
                    <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                        <div className="alert alert-danger d-flex align-items-center" role="alert">
                            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none" className="bi bi-ban" viewBox="0 0 16 16"><path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" fill="#d82216" /></svg>
                            <div className='messageDetails'>This request cannot be approved</div>
                        </div>
                    </div>
                </div>
            }
            {
                (state === 404) &&
                <div className="container">
                    <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <svg width="25" height="22" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.8827 1.30997C11.0503 -0.103484 8.94972 -0.103485 8.11728 1.30997L0.28891 14.6021C-0.537147 16.0047 0.506664 17.7499 2.17163 17.7499H17.8284C19.4933 17.7499 20.5371 16.0047 19.7111 14.6021L11.8827 1.30997ZM11.25 5.87488H8.75L9.0625 11.4999H10.9375L11.25 5.87488ZM10.9375 12.7499H9.0625V14.6249H10.9375V12.7499Z" fill="#ffcf24" /></svg>
                            <div className='messageDetails'>This request cannot be approved</div>
                        </div>
                    </div>
                </div>
            }
            {
                (state === 304) &&
                <div className="container">
                    <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <svg width="25" height="22" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.8827 1.30997C11.0503 -0.103484 8.94972 -0.103485 8.11728 1.30997L0.28891 14.6021C-0.537147 16.0047 0.506664 17.7499 2.17163 17.7499H17.8284C19.4933 17.7499 20.5371 16.0047 19.7111 14.6021L11.8827 1.30997ZM11.25 5.87488H8.75L9.0625 11.4999H10.9375L11.25 5.87488ZM10.9375 12.7499H9.0625V14.6249H10.9375V12.7499Z" fill="#ffcf24" /></svg>
                            <div className='messageDetails'>This request cannot be approved</div>
                        </div>
                    </div>
                </div>
            }
            {
                (state === 500) &&
                <div className="container">
                    <div className="d-flex flex-column mt-5 justify-content-center align-items-center">
                        <div className="alert alert-danger d-flex align-items-center" role="alert">
                            <svg width="25" height="22" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.8827 1.30997C11.0503 -0.103484 8.94972 -0.103485 8.11728 1.30997L0.28891 14.6021C-0.537147 16.0047 0.506664 17.7499 2.17163 17.7499H17.8284C19.4933 17.7499 20.5371 16.0047 19.7111 14.6021L11.8827 1.30997ZM11.25 5.87488H8.75L9.0625 11.4999H10.9375L11.25 5.87488ZM10.9375 12.7499H9.0625V14.6249H10.9375V12.7499Z" fill="#d82216" /></svg>
                            <div className='messageDetails'>This request cannot be approved</div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default RequestDecline;
