import { Route, Routes } from 'react-router-dom';
import Layout from '../shared/Layout';
import RequestApprove from '../resolveRequest/RequestApprove';
import RequestDecline from '../resolveRequest/RequestDecline';
import { PrivacyPolicyPage } from '../pages/privacyPolicy/PrivacyPolicyPage';
import { TermsAndConditionsPage } from '../pages/termsAndConditions/TermsAndConditionsPage';
import { SupportFormPage } from '../pages/support/SupportFormPage';
import { RequestFormPage } from '../pages/request/RequestFormPage';
import { AcknowledgmentFormPage } from '../pages/acknowledgement/AcknowledgmentFormPage';
import { FrequentlyAskedQuestionsPage } from '../pages/frequentlyAskedQuestions/FrequentlyAskedQuestionsPage';
import { RequestConfirmationPage } from '../pages/request/RequestConfirmationPage';
import { SupportConfirmationPage } from '../pages/support/SupportConfirmationPage';
import { AcknowledgmentConfirmationPage } from '../pages/acknowledgement/AcknowledgmentConfirmationPage';
import { NotFoundPage } from '../pages/notFound/NotFoundPage';
import { NotLoadedPage } from '../pages/notFound/NotLoadedPage';

function RouteHandler() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/">
          <Route index element={<RequestFormPage />} />
          <Route path='privacyPolicy' element={<PrivacyPolicyPage />}></Route>
          <Route path='termsOfUse' element={<TermsAndConditionsPage />}></Route>
          <Route path='frequentlyAskedQuestions' element={<FrequentlyAskedQuestionsPage />}></Route>
          <Route path='support' element={<SupportFormPage />}></Route>
          <Route path='supportConfirmation' element={<SupportConfirmationPage />}></Route>
          <Route path='request' element={<RequestFormPage />}></Route>
          <Route path='requestConfirmation' element={<RequestConfirmationPage />}></Route>
          <Route path='acknowledgment/:token' element={<AcknowledgmentFormPage />}></Route>
          <Route path='acknowledgement/:token' element={<AcknowledgmentFormPage />}></Route>
          <Route path='acknowledgmentConfirmation' element={<AcknowledgmentConfirmationPage />}></Route>
          <Route path='notLoaded' element={<NotLoadedPage />}></Route>
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
      <Route path='approve/:token' element={<RequestApprove />}></Route>
      <Route path='decline/:token' element={<RequestDecline />}></Route>
    </Routes>
  );
}

export default RouteHandler;