import resources from './Errors.resources.json';
import './ErrorBox.css';
import { Error } from './models/Models';
import { ErrorIcon } from './ErrorIcon';


export const ErrorBox = ({ code, showBorder }: { code: number, showBorder?: boolean }) => {

  const error = (resources as Error[])?.find(id => id.Code === code);

  return (
    <div className="text-center pb-4">
      <div className={(showBorder ?? false) ? "box" : "pt-4"} >
        <h4 className='title'><ErrorIcon svg={error?.Icon} />{error?.Title}</h4>
        <p>{error?.Description}</p>
      </div>
    </div>
  );
};
