import resources from './Support.resources.json';
import { useNavigate } from 'react-router';

const SupportConfirmation = () => {

  const navigator = useNavigate();


  return (
    <div className="whiteback requestConfirmationPage text-center">
      <div className="row">
      </div>
      <div className="row pb-4">
        <h4>{resources.supportConfirmation.title}</h4>
      </div>
      <div className="row pb-4">
        <p>{resources.supportConfirmation.message}</p>
      </div>
      <div className="helpButton text-center">
        <button type="button" className="btn btn-secondary me-2" onClick={() => navigator("/")}>{resources.supportConfirmation.goToHome}</button>
      </div>
    </div>
  );
}

export default SupportConfirmation;