const envSettings = (window as any);

const config = {
  api_proxy_url: envSettings.API_PROXY_URL ?? "http://localhost:5191",
  key: envSettings.KEY,
  captchaKey: envSettings.CAPTCHAKEY,
  rumEnvironment: envSettings.RUMENVIRONMENT,
  rumApplicationID: envSettings.RUMAPPLICATIONID,
  rumClientToken: envSettings.RUMCLIENTTOKEN,
  rumService: envSettings.RUMSERVICE,
  feature_UxUpgradeAvailable: envSettings.FEATURE_UX_UPGRADE_AVAILABLE === 'true'
};

export default config;