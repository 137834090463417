import {useEffect, useState} from "react";

function useIsMobile(): boolean {
    const mobileMaxWidth = 800;
    const [isMobileDevice, setMobileDevice] = useState(window.innerWidth < mobileMaxWidth);
    const updateMedia = () => {
        setMobileDevice(window.innerWidth < mobileMaxWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });
    return isMobileDevice;
}

export default useIsMobile;