import { OverlayTrigger, Tooltip } from "@bcg-web/bcg-react-bootstrap";

export const ToolTipIcon = ({ toolTip }: { toolTip: string | undefined }) => {
  return (
    <>
      <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip1">{toolTip}</Tooltip>}>
        <span className="ps-2">
          <svg width="16" height="24" viewBox="0 5 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1806_5886)">
              <path d="M7.25 11.7498H8.75V6.74972H7.25001L7.25 11.7498Z" fill="#323232" />
              <path d="M7.25 4.24979V5.74976H8.75V4.24979H7.25Z" fill="#323232" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16 8.00002C16 3.58182 12.4183 0.000160217 8 0.000160217C3.58172 0.000160217 0 3.58182 0 8.00002C0 12.4182 3.58172 15.9999 8 15.9999C12.4183 15.9999 16 12.4182 16 8.00002ZM14.5 8.00002C14.5 4.41027 11.5899 1.50016 8 1.50016C4.41012 1.50016 1.5 4.41027 1.5 8.00002C1.5 11.5898 4.41012 14.4999 8 14.4999C11.5899 14.4999 14.5 11.5898 14.5 8.00002Z" fill="#323232" />
            </g>
            <defs>
              <clipPath id="clip0_1806_5886">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </OverlayTrigger >
    </>
  );
};





