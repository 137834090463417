import { SplitLayout } from "../../shared/SplitLayout";
import UseIsMobile from "../../../services/useIsMobile";
import document from "./Document.json";
import { Document } from '../../documentViewer/models/Document';
import { Stack } from '../../stack/Stack';
import { Orientation } from '../../stack/models/Orientation';
import { PageViewer } from '../../documentViewer/PageViewer';
import { AccordionViewer } from '../../documentViewer/AccordionViewer';
import { PageNavigator } from "../../documentViewer/PageNavigator";
import { RequestBox } from "../../request/RequestBox";

export const PrivacyPolicyPage = () => {

  return (
    <>
      {UseIsMobile() &&
        <div className="container">
          <AccordionViewer data={document as Document} />
        </div>
      }
      {!UseIsMobile() &&
        <SplitLayout
          left={
            <PageViewer data={document as Document} />
          }
          right={
            <Stack orientation={Orientation.VERTICAL}>
              <RequestBox />
              <PageNavigator data={document as Document} />
            </Stack>
          } 
        ></SplitLayout>
      }
    </>
  );
}
