import axios from 'axios';
import config from '../../utils/config';
import { Acknowledgment, Response, Project, Image } from './models/Models';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"

const Service = {

  acknowledge: async (acknowledgement: Acknowledgment) => {
    const url = `${config.api_proxy_url}/acknowledgments`;
    return axios.post<Response>(url, acknowledgement)
      .then(response => {
        return response.data;
      })
      .catch((error: unknown) => {
        throw error;
      });
  },

  getProject: async (token: string | undefined) => {
    const url = `${config.api_proxy_url}/projects/${token}`;
    return axios.get<Project>(url)
      .then(response => {
        return response.data;
      })
      .catch((error: unknown) => {
        throw error;
      });
  },

  getImages: async (token: string | undefined) => {
    const url = `${config.api_proxy_url}/templates/${token}`;
    return axios.get<Image[]>(url)
      .then(response => {
        return response.data;
      })
      .catch((error: unknown) => {
        throw error;
      });
  }
};
export default Service;
