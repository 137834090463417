import { SplitLayout } from "../../shared/SplitLayout";
import UseIsMobile from "../../../services/useIsMobile";
import { Stack } from '../../stack/Stack';
import { Orientation } from '../../stack/models/Orientation';
import { RequestBox } from "../../request/RequestBox";
import { Background } from "../../stack/models/Background";
import { AcknowledgmentConfirmation } from "../../acknowledgment/AcknowledgmentConfirmation";

export const AcknowledgmentConfirmationPage = () => {

  return (
    <>
      {UseIsMobile() &&
        <div className="container">
          <AcknowledgmentConfirmation />
        </div>
      }
      {!UseIsMobile() &&
        <SplitLayout
          left={
            <AcknowledgmentConfirmation />
          }
          right={
            <Stack orientation={Orientation.VERTICAL} background={Background.GREY}>
              <RequestBox />
            </Stack>
          }
        ></SplitLayout>
      }
    </>
  );
}
