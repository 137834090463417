import { SplitLayout } from "../../shared/SplitLayout";
import UseIsMobile from "../../../services/useIsMobile";
import { Stack } from '../../stack/Stack';
import { Orientation } from '../../stack/models/Orientation';
import { Background } from "../../stack/models/Background";
import { ErrorBox } from "../../errors/ErrorBox";
import { SupportBox } from "../../support/SupportBox";
import { useNavigate } from "react-router";
import resources from '../../../components/errors/Messages.resources.json';

export const NotFoundPage = () => {
  const navigator = useNavigate();

  return (
    <>
      {UseIsMobile() &&
        <div className="container">
          <ErrorBox code={404} />
          <div className="helpButton text-center">
            <button type="button" className="btn btn-secondary me-2" onClick={() => navigator("/")}>{resources.GoTo}</button>
          </div>
        </div>
      }
      {!UseIsMobile() &&
        <SplitLayout
          left={
            <>
              <ErrorBox code={404} />
              <div className="helpButton text-center">
                <button type="button" className="btn btn-secondary me-2" onClick={() => navigator("/")}>{resources.GoTo}</button>
              </div>
            </>
          }
          right={
            <Stack orientation={Orientation.VERTICAL} background={Background.WHITE}>
              <SupportBox />
            </Stack>
          }
        ></SplitLayout>
      }
    </>
  );
}