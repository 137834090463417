import axios from 'axios';
import config from '../../utils/config';
import { SupportMail } from './models/SupportMail';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"

const endpoint = "/support";

const service = {

    sendSupportMail: async (supportMail: SupportMail) => {
        const url = `${config.api_proxy_url}${endpoint}`;
        return axios.post<SupportMail>(url, supportMail)
            .then(response => {
                return response.data;
            })
            .catch((error: unknown) => {
                throw error;
            });
    },
};

export default service;