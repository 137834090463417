import { SplitLayout } from "../../shared/SplitLayout";
import UseIsMobile from "../../../services/useIsMobile";
import { Stack } from '../../stack/Stack';
import { Orientation } from '../../stack/models/Orientation';
import { SupportForm } from "../../support/SupportForm";
import { RequestBox } from "../../request/RequestBox";

export const SupportFormPage = () => {

  return (
    <>
      {UseIsMobile() &&
        <div className="container">
          <SupportForm />
        </div>
      }
      {!UseIsMobile() &&
        <SplitLayout
          left={
            <SupportForm />
          }
          right={
            <Stack orientation={Orientation.VERTICAL}>
              <RequestBox />
            </Stack>
          }
        ></SplitLayout>
      }
    </>
  );
}
