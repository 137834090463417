import React from 'react';
import { BrowserRouter as Router, } from 'react-router-dom';
import '@bcg-web/bcg-bootstrap/dist/bcg-bootstrap.min.css';
import RouteHandler from '../routeHandler/RouteHandler';
import { datadogRum } from '@datadog/browser-rum'
import config from '../../utils/config';

datadogRum.init({
    applicationId: config.rumApplicationID,
    clientToken: config.rumClientToken,
    site: 'datadoghq.com',
    service: config.rumService,
    env: config.rumEnvironment,
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 80,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});


function App() {
    return (
        <Router basename="/nrl">
            <RouteHandler/>
        </Router>
    );
}

export default App;